<template>
  <Form page-title="Add a new Holiday" :business-id="businessId"
        :create-form="true" :in-list="inList" @submit="submit" ref="HolidayForm" />
</template>
<script>
import Form from "./Form";
import {createHoliday} from "@/API/HolidaysAPIs";
import {submitAndNotify} from "@/helpers/utils";

export default {
  name: "CreateHoliday",
  props:['inList', 'businessId'],
  components: {Form},
  methods: {
    submit(data) {
      let callback = ()=> this.$refs.HolidayForm.resetForm();
      submitAndNotify(this, createHoliday(data), callback.bind(this));
    }
  }
}
</script>
<style>
</style>
