<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="validationObserver">
    <form @submit.prevent="handleSubmit(submit)" ref="form">
      <div :class="inList ? 'page-container py-0' : 'page-container bg-white py-4 px-5' ">
        <h4 :class="inList ? 'card-title':'card-title pb-4'">
          {{ pageTitle }}
        </h4>
        <FormGenerator :fields="fields" :model="data" :options="options" />
        <div class="text-right">
          <p-button type="warning" native-type="submit">Submit</p-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import FormGenerator from "@/components/Dashboard/Common/FormGenerator";
import {models,options,fields} from "@/pages/Holidays/form_fields";
import {getHolidayData} from "@/API/HolidaysAPIs";
import moment from 'moment';

export default {
  name: "Form",
  components: {
    FormGenerator
  },
  props: [
    'id',
    'pageTitle',
    'inList',
    'model',
    'businessId',
    'createForm'
  ],
  data() {
    return {
      data: { ...models },
      options: options,
      fields: fields,
      emptyModel: null
    }
  },
  async mounted() {
    const defaultDateRange = {
      startDate: moment().format('YYYY-MM-DDT14:00:00.000Z'),
      endDate: moment().add(1, 'days').format('YYYY-MM-DDT14:00:00.000Z'),
    }
    models.dateRange = defaultDateRange;
    this.data.business_id = this.businessId;
    this.emptyModel = JSON.stringify(models);
    await this.getData();
    this.options.business_idOptions = this.$store.state.user.business;

    if(this.data.id){
      this.data.dateRange = {
        startDate: this.data.from,
        endDate: this.data.to
      };
    }else {
      this.data.dateRange = defaultDateRange;
    }
  },
  watch: {
    async id(val) {
      if (this.id)
        this.data = await getHolidayData(val);
    },
    model:{
      instant: true,
      deep:true,
      handler(value){
        this.data = value;
        this.data.dateRange = {};
        this.data.dateRange = {
          startDate: value.from,
          endDate: value.to
        };
      }
    }
  },
  methods: {
    submit() {
      this.data.from = moment(this.data.dateRange.startDate).format('YYYY-MM-DD 14:00:00');
      this.data.to = moment(this.data.dateRange.endDate).format('YYYY-MM-DD 14:00:00');
      this.data.businessId = this.businessId;
      this.$emit('submit', this.data);
      this.$refs.validationObserver.reset();
    },
    async resetForm() {
      this.data = JSON.parse(this.emptyModel);
      await this.getData();
    },
    async getData(){
      if (!this.id)
        this.id = this.$route.params.id;
      if (this.id)
        return this.data = await getHolidayData(this.id);
      if (this.model)
        return this.data = this.model;
    }
  }
}
</script>

<style scoped>

</style>
