import {__localesModel, __localizedField} from "@/util/locales";
import {__DateOptions} from "@/util/dates";

export const models = {
  locales: __localesModel,
  dateRange: { ...__DateOptions },
}
export const options = {
}

export const fields = [
  __localizedField,
  {
    label: 'Date range',
    name: "dateRange",
    placeholder: 'Select the start date',
    type: 'date-range',
    value: '',
    format: 'dd-mm-yyyy HH:mm:ss',
    rules: 'required',
    class:'col-sm-12',
    validation_messages: {
      required: 'This field is required'
    }
  },
];
