<template>
    <Form page-title="Edit Holiday" :business-id="businessId"
          :in-list="inList" :model="model" @submit="submit" ref="ColorForm"/>
</template>
<script>
import Form from "./Form";
import {updateHoliday} from "@/API/HolidaysAPIs";
export default {
  name: "EditHoliday",
  components: {Form},
  props: ['inList', 'model','businessId'],
  methods: {
    submit(data) {
      let loader = this.$loading.show();
      updateHoliday(data)
        .then(() => {
          this.$emit('onSubmit');
          this.$notify({message: 'Updated successfully', type: 'success'});
        })
        .catch((error) => {
            let errorMessage = "";
            if (error.response && error.response.data && error.response.data.message)
              errorMessage = error.response.data.message;
            else
              errorMessage = 'An unexpected error occurred.';
            this.$error(errorMessage)
        })
        .finally(() => loader.hide())
    }
  }
}
</script>
<style>
</style>
