<template>
  <div class="edit-items-container">
    <b-button class="btn pull-end close-btn"
              variant="default-outline" @click="$emit('close-gallery')">X
    </b-button>


    <div class="edit-items" v-if="holidays">
      <div class="p-3 ">
        <b-link to='/holidays'> Manage holidays</b-link>
      </div>
      <b-row v-for="holiday in holidays" :key="holiday.id"
             class="row mx-2 py-2 border-top-1 edit-item">
        <b-col sm="5">
          <label class=" d-flex align-items-center small mb-0 " style="height: 30px"> {{ holiday.name }} </label>
        </b-col>
        <b-col sm="4" class="px-0">
          <b-input type="number" v-model="holiday.price"/>
        </b-col>
        <b-col sm="3">
          <span class=" d-flex align-items-center small mb-0 " style="height: 30px">KWD</span>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-end px-2 bg-white">
        <button @click="syncHolidays" class="btn btn-primary "> Save</button>
      </div>


    </div>
    <div class="no-items mt-3">
      <b-alert variant="primary" show v-if="!allHolidays.length">
        You don't have holidays
      </b-alert>
    </div>
  </div>
</template>

<script>
import {getHolidaysList, syncHoliday} from "@/API/HolidaysAPIs";

export default {
  name: 'HolidayPrices',
  props: ['holidayItem', 'businessId'],

  data() {
    return {
      holidays: [],
      allHolidays: [],
      itemHolidays: [],

    };
  },
  mounted() {
    this.getHolidays();
  },
  methods: {
    async getHolidays() {
      this.allHolidays = (await getHolidaysList({businessId: this.businessId})).data;
      this.setHolidays();
    },
    setHolidays() {
      this.itemHolidays = this.holidayItem?.holidays;
      this.holidays = [];
      this.allHolidays.forEach(holiday => {
        let price = this.itemHolidays.filter(h => h.id === holiday.id)[0]?.price ?? null;
        this.holidays.push({holidayId: holiday.id, price, name: (this.__t(holiday.locales).name)});
      })

    },
    async syncHolidays() {
      const loading = this.$loading.show();
      try{
        await syncHoliday({businessId: this.businessId, itemId: this.holidayItem.id}, {holidays: this.holidays});
        this.$emit('refreshMenu');
      }catch (error) {
        let errorMessage = "";
        if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        } else {
          errorMessage = 'An unexpected error occurred.';
        }
        this.$error(errorMessage)
      }
      loading.hide();
    }

  },
  watch: {
    holidayItem: {
      deep: true,
      handler(value) {
        this.setHolidays();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.edit-items-container {
  background: #f4f3ef;
  padding: 5px;
  border: #e3e3e3 1px solid;
  border-radius: 10px;
  border-top-right-radius: 0;
}

.close-btn {
  padding: 10px;
  position: absolute;
  right: 0;
  margin-right: -13px;
  top: -10px;
  background: #f4f3ef;
  color: #000;
  font-size: 1.1em;
  border: #e3e3e3 1px solid;
  border-radius: 0;
  border-left: 0;
}

.edit-items {
  background: white;
}

.edit-item {
  background: #f6f6f6;
}

.border-top-1 {
  border-top: #eee 1px solid;
}
</style>
